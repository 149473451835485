import React from "react";
import Message from "../components/Message";

export const Messages = () => {
  return (
    <>
      <Message />
    </>
  );
};
